import { Col, Row } from 'antd';
import { Header, InternalContainer, PageContent } from './basic-styles';
import { Link, graphql } from 'gatsby';

import DOMPurify from 'isomorphic-dompurify';
import PageHeader from '../components/common/page-header';
import PublicLayout from '../layout/public-layout';
import React from 'react';
import styled from '@emotion/styled';
import { useI18NContext } from '../i18n';

type Basic = {
  data: {
    page: {
      translation: {
        basic_page_content: string;
        basic_page_title: string;
      };
    };
  };
};

const StyledHeader = styled(Header)`
  padding-bottom: 40px;
  padding-top: 0;
`;

// The query used to provide the page data
export const query = graphql`
  query($type: String!, $language: String) {
    page: directusBasicPage(basic_page_type: { basic_type_name: { eq: $type } }) {
      translation(language: $language) {
        basic_page_title
        basic_page_content
      }
    }
  }
`;

const BasicTemplate = ({ data: { page } }: Basic) => {
  const { translateSlug } = useI18NContext();
  return (
    <PublicLayout seoTitle={page.translation?.basic_page_title}>
      <InternalContainer>
        <Row justify="start">
          <Col>
            <PageHeader
              title=""
              breadcrumb={[
                <Link key="1" to={translateSlug('/')}>
                  Home
                </Link>,
                page.translation?.basic_page_title,
              ]}
              fullWidth
              isResponsive
              noShadow
            />
            <StyledHeader>{page.translation?.basic_page_title}</StyledHeader>
            <PageContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(page.translation?.basic_page_content, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] }),
              }}
            />
          </Col>
        </Row>
      </InternalContainer>
    </PublicLayout>
  );
};

export default BasicTemplate;
